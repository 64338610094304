class LineBreak {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-linebreak'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$lineBreak = $element;
        this.resizeHandler = window.resizeHandler;

        this.initialize();
    }

    initialize () {
        this.setWidth();

        this.resizeHandler.customFunctions.push(() => {
            this.setWidth();
        });
    }

    setWidth () {
        this.$lineBreak.removeAttribute('style');
        const currentWidth = this.$lineBreak.offsetWidth + 1;
        this.$lineBreak.style.width = currentWidth + 'px';
        this.$lineBreak.style.display = 'inline-block';
    }
}

export { LineBreak };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$lineBreak = $context.querySelectorAll('[data-linebreak');
        for (let i = 0; i < $$lineBreak.length; i++) {
            const $lineBreak = $$lineBreak[i];

            const lineBreakAPI = new LineBreak($lineBreak);
            $lineBreak.API = lineBreakAPI;
        }
    }
});
